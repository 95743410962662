










































import BaseClass from '@/base';
import { Component } from 'vue-property-decorator';

@Component
export default class Login extends BaseClass {
    email = '';
    password = '';

    async login() {
        this.loading = true;

        try {
            const result = await this.firebase
                .auth()
                .signInWithEmailAndPassword(this.email, this.password);
            const idTokenResult = await this.firebase
                .auth()
                .currentUser?.getIdTokenResult();

            if (!idTokenResult || !idTokenResult.claims.admin) {
                throw new Error('You are not allowed to access this area.');
            } else {
                document.location.href = '/';
            }
        } catch (e) {
            this.snackMessage(e.message);
        }
        this.loading = false;
    }
}
